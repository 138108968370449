import { i18n } from "./i18n";
import {
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
} from "../constants/localizationConstants";

const loadedLocales: string[] = [];
const Trans = {
  // get currency active locale from i18n
  getCurrentLocale() {
    return i18n.global.locale;
  },
  //set current locale in i18n
  setCurrentLocale(locale: string) {
    i18n.global.locale.value = locale;
  },
  // get locale supported by our app
  getDefaultLocaleAndLoadMessages() {
    const userPreferredLocale = Trans.getBrowserPrefLocale();
    let browserPrefLocale = "";
    if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
      browserPrefLocale = userPreferredLocale.locale;
    } else if (Trans.isLocaleSupported(userPreferredLocale.localeNoISO)) {
      browserPrefLocale = userPreferredLocale.localeNoISO;
    } else {
      browserPrefLocale = DEFAULT_LANGUAGE;
    }

    //* retrieving any user saved local i.e local storage
    //* if yes lazy load local saved locale messages
    //* if no lazy load browser preferred language locale
    const localSavedLocale = localStorage.getItem("reduzer-portfolio-locale");
    if (!localSavedLocale) {
      localStorage.setItem("reduzer-portfolio-locale", browserPrefLocale);
      Trans.loadInitialLocaleMessages(browserPrefLocale);
      return browserPrefLocale;
    } else {
      Trans.loadInitialLocaleMessages(localSavedLocale);
      return localSavedLocale;
    }
  },

  //* get user locale from browser language preference
  getBrowserPrefLocale() {
    let locale = window.navigator.language || DEFAULT_LANGUAGE;
    if (locale === "nn" || locale === "nb") {
      locale = "no";
    }
    return {
      locale: locale,
      localeNoISO: locale.split("-")[0],
    };
  },

  //* update locale in i18n
  setI18nLocaleInServices(locale: string) {
    Trans.setCurrentLocale(locale);
    return locale;
  },
  async loadInitialLocaleMessages(locale: string) {
    if (!Trans.isLocaleSupported(locale))
      return Promise.reject(new Error("Locale not supported"));
    if (!loadedLocales.includes(locale)) {
      const msgs = await Trans.loadLocaleFile(locale);
      i18n.global.setLocaleMessage(locale, msgs.default || msgs);
      loadedLocales.push(locale);
    }
  },
  // function to handle change in locale
  async changeLocale(locale: string) {
    if (!Trans.isLocaleSupported(locale))
      return Promise.reject(new Error("Locale not supported"));
    if (i18n.global.locale.value === locale) return Promise.resolve(locale);

    if (!loadedLocales.includes(locale)) {
      //* lazy loading locale messages
      const msgs = await Trans.loadLocaleFile(locale);
      i18n.global.setLocaleMessage(locale, msgs.default || msgs);

      //* saving locale in loadedLocales
      loadedLocales.push(locale);
    }

    //* setting current locale on i18n
    return Trans.setI18nLocaleInServices(locale);
  },

  //* load the messages file based on locale
  loadLocaleFile(locale: any) {
    return import(`@/locales/${locale}.json`);
  },

  //* check if locale is supported
  isLocaleSupported(locale: string) {
    return SUPPORTED_LANGUAGES.includes(locale);
  },
};

export { Trans };
