<template>
  <v-snackbar
    :model-value="snackbar.show"
    @update:model-value="snackbar.reset()"
    location="top right"
    :timeout="snackbar.timeout"
    :transition="snackbar.transition"
    :color="snackbarColor"
    z-index="99999"
  >
    {{ snackbar.text }}
  </v-snackbar>
  <router-view />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useSnackbar } from "@/store/snackbar/";

const snackbar = useSnackbar();

const snackbarColor = computed<string | undefined>(() => {
  if (snackbar.type === "success") return "green";
  else if (snackbar.type === "error") return "red";
  else if (snackbar.type === "warning") return "orange";
  else return undefined;
});
</script>

<style>
html {
  scroll-behavior: smooth !important;
}
</style>
