import type { IdTokenResult } from "@firebase/auth";
import type { ProjectDoc } from "common/@types/services/project";
import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "vuefire";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        name: "ResetPassword",
        path: "/reset-password",
        component: () => import("@/views/authentication/ResetPassword.vue"),
      },
      {
        name: "Login",
        path: "/login",
        component: () => import("@/views/authentication/Login.vue"),
      },
      {
        name: "SAManageClient",
        path: "/manage-clients",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN"],
        },
        component: () => import("@/views/superAdmin/ManagePortfolioClient.vue"),
      },
      {
        name: "SAManageSuperAdmin",
        path: "/manage-super-admins",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN"],
        },
        component: () => import("@/views/superAdmin/ManageSuperAdmins.vue"),
      },
      // {
      //   name: "SyncData",
      //   path: "/sync-data",
      //   meta: {
      //     requiresAuth: true,
      //     accessibleTo: ["SUPER_ADMIN"],
      //   },
      //   component: () => import("@/views/superAdmin/SyncData.vue"),
      // },
      {
        name: "SuperAdminManageProfile",
        path: "/manage-profile",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN"],
        },
        component: () => import("@/views/profile/ManageProfile.vue"),
      },
      {
        name: "SuperAdminResetPassword",
        path: "/change-password",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN"],
        },
        component: () => import("@/views/profile/ChangePassword.vue"),
      },
      {
        name: "Home",
        path: "",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN"],
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/HomeView.vue"),
      },
    ],
  },
  {
    path: "/c/:clientId",
    component: () => import("@/layouts/Client.vue"),
    children: [
      {
        name: "InstructionsGuide",
        path: "/c/:clientId/instructions-guide",
        component: () => import("@/views/admin/InstructionsGuide.vue"),
      },
      {
        name: "PublishedReports",
        path: "/c/:clientId/published-reports",
        alias: "/c/:clientId",
        component: () => import("@/views/portfolio/PublishedReports.vue"),
      },
      {
        name: "Projects",
        path: "/c/:clientId/projects",
        component: () => import("@/views/portfolio/Projects.vue"),
      },
      {
        name: "CreateNewProject",
        path: "/c/:clientId/create-new-project",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/HomeView.vue"),
      },
      {
        name: "ViewSubmissions",
        path: "/c/:clientId/view-submissions",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/portfolio/ViewSubmissions.vue"),
      },
      {
        name: "ProjectsShared",
        path: "/c/:clientId/projects-shared",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/project/index.vue"),
      },
      {
        name: "ManageUserExperience",
        path: "/c/:clientId/manage-user-experience",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/admin/ManageUserExperience.vue"),
      },
      {
        name: "ManageAdminAccounts",
        path: "/c/:clientId/manage-admin-accounts",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN", "ADMIN"],
        },
        component: () => import("@/views/admin/ManageAdminAccounts.vue"),
      },
      {
        name: "ClientManageProfile",
        path: "/c/:clientId/manage-profile",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/profile/ManageProfile.vue"),
      },
      {
        name: "ClientResetPassword",
        path: "/c/:clientId/change-password",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/profile/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/c/:clientId/p/",
    component: () => import("@/layouts/Project.vue"),
    children: [
      {
        name: "ProjectsView",
        path: "/c/:clientId/p/",
        meta: {
          requiresAuth: true,
          accessibleTo: ["PROJECT_USER"],
          // loginType: "customAuth",
        },
        component: () => import("@/views/project/index.vue"),
      },
      {
        name: "ProjectUserChangePassword",
        path: "/c/:clientId/p/:projectId/change-password",
        alias: "/c/:clientId/p/change-password",
        meta: {
          requiresAuth: true,
          accessibleTo: ["PROJECT_USER"],
          // loginType: "customAuth",
        },
        component: () => import("@/views/profile/ChangePassword.vue"),
      },
      {
        name: "ProjectDashboard",
        path: "/c/:clientId/p/:projectId",
        meta: {
          requiresAuth: true,
          accessibleTo: ["ADMIN", "SUPER_ADMIN", "PROJECT_USER"],
          // loginType: "customAuth",
        },
        component: () => import("@/views/project/Project.vue"),
      },
      // {
      //   name: "ProjectUserChangePassword",
      //   path: "/c/:clientId/p/:projectId/change-password",
      //   meta: {
      //     requiresAuth: true,
      //     accessibleTo: ["PROJECT_USER"],
      //     // loginType: "customAuth",
      //   },
      //   component: () => import("@/views/profile/ChangePassword.vue"),
      // },
      {
        name: "ManageProjectUsers",
        path: "/c/:clientId/p/:projectId/manage-users",
        meta: {
          requiresAuth: true,
          accessibleTo: ["ADMIN", "SUPER_ADMIN", "PROJECT_USER"],
          // loginType: "customAuth",
        },
        component: () => import("@/views/project/ManageUsers.vue"),
      },
      {
        name: "ProjectInstructionsGuide",
        path: "/c/:clientId/p/:projectId/instructions-guide",
        component: () => import("@/views/project/InstructionsGuide.vue"),
      },
      {
        name: "ProjectPublicDashboard",
        path: "/c/:clientId/project-view/:projectId",
        component: () => import("@/views/project/Project.vue"),
      },
      {
        name: "ReportDashboard",
        path: "/c/:clientId/p/:projectId/r/:reportId",
        meta: {
          requiresAuth: true,
          accessibleTo: ["ADMIN", "SUPER_ADMIN", "PROJECT_USER"],
          // loginType: "customAuth",
        },
        component: () => import("@/views/project/Report.vue"),
      },
      {
        name: "ReportReviewPage",
        path: "/c/:clientId/p/:projectId/report-review/:reportId",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN", "ADMIN"],
        },
        component: () => import("@/views/project/Report.vue"),
      },
      {
        name: "ReduzerReportViewPage",
        path: "/c/:clientId/p/:projectId/reduzer-report/:reportId",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN", "ADMIN", "PROJECT_USER"],
        },
        component: () => import("@/views/project/Report.vue"),
      },
      {
        name: "ReduzerReportReviewPage",
        path: "/c/:clientId/p/:projectId/reduzer-report-review/:reportId",
        meta: {
          requiresAuth: true,
          accessibleTo: ["SUPER_ADMIN", "ADMIN"],
        },
        component: () => import("@/views/project/Report.vue"),
      },
      {
        name: "PublishedReportPage",
        path: "/c/:clientId/p/:projectId/published-report/:reportId",
        component: () => import("@/views/project/Report.vue"),
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/notFound/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  /**
   * * workaround in case if we use futurebuilt endpoint.
   * * this will prevent accessing to other client ids using future built domain
   * * it'll only allow futurebuilt as client id if request from futurebuilt.no
   */

  if (
    ["Projects", "ProjectsShared"].includes(from.name as string) &&
    to.name === "ProjectDashboard"
  ) {
    sessionStorage.setItem("rpBackLink", from.path);
  }

  if (
    window.location.host.includes("futurebuilt.no") &&
    from.params.clientId != "future-built"
  ) {
    return {
      path: "/not-found",
    };
  }

  // * routes with `meta: { requiresAuth: true }` will check for the users, others won't
  // const query = { type: null } as { type: string | null };
  if (
    to.query.redirectTo &&
    to.query.redirectTo.includes("/p/") &&
    from.name != "PublishedReportPage"
  ) {
    // query["type"] = "customAuth";
  }
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser();
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      // we keep the current path in the query so we can redirect to it after login
      // with `router.push(route.query.redirectTo || '/')`
      // if (from.meta.loginType && from.meta.loginType === "customAuth") {
      //   query["type"] = "customAuth";
      // }

      return {
        path: "/login",
        query: { redirectTo: to.fullPath },
      };
    } else {
      if (to.meta.accessibleTo) {
        const idTokenResults = await currentUser.getIdTokenResult();
        const claims = idTokenResults.claims as IdTokenResult & {
          SUPER_ADMIN?: boolean;
          ADMIN?: boolean;
          PROJECT_USER?: boolean;
          [key: string]: any;
        };

        const accessibleTo = to.meta.accessibleTo as [];
        let isAccessAllowed = false;
        accessibleTo.forEach((accessibleTo) => {
          if (claims[accessibleTo]) {
            isAccessAllowed = true;
          }
        });
        if (!isAccessAllowed) {
          if (claims["SUPER_ADMIN"]) {
            return { path: "/" };
          } else if (claims["ADMIN"]) {
            return { path: `/c/resolve-client` };
          } else if (claims["PROJECT_USER"]) {
            const projectDoc = claims["PROJECT"] as ProjectDoc;
            return { path: `/c/resolve-client/p` };
          }
          return {
            path: "/not-found",
          };
        }
      }
    }
  }
  // else {
  //   if (query["type"] && !to.query.type) {
  //     return {
  //       path: to.path,
  //       query: { ...to.query, type: query["type"] },
  //     };
  //   }
  // }
});

export default router;
