import type { App } from "vue";
import { VueFire } from "vuefire";
import { loadFonts } from "./webfontloader";
import firebase from "./firebase";
import vuetify from "./vuetify";
import pinia from "../store";
import router from "../router";
import { i18n } from "./i18n";

export function registerPlugins(app: App) {
  loadFonts();
  app.use(vuetify).use(VueFire, firebase).use(router).use(pinia).use(i18n);
}
