const appConfig = {
  firebaseApiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  firebaseAuthDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId: import.meta.env
    .VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  firebaseMeasurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
  cloudFunctionsRegion: import.meta.env.VITE_APP_CLOUD_FUNCTIONS_REGION,
};

export default appConfig;
