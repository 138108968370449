// Vuetify
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labs from "vuetify/labs/components";

const getThemeMode = (): "dark" | "light" => {
  const theme = localStorage.getItem("reduzer-portfolio-theme");
  if (theme) {
    return theme === "dark" ? "dark" : "light";
  }
  return "light";
  // else {
  //   if (
  //     window.matchMedia &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  //   ) {
  //     localStorage.setItem("reduzer-portfolio-theme", "dark");
  //     return "dark";
  //   } else {
  //     localStorage.setItem("reduzer-portfolio-theme", "light");
  //     return "light";
  //   }
  // }
};

const theme = {
  primary: "#008663",
  secondary: "#FFFFFF",
  accent: "#508e71",
  green: "#008663",
  danger: "#DC2626",
  mute: "e6e7e9",
};

export default createVuetify({
  components: {
    ...components,
    ...labs,
  },
  directives,
  theme: {
    defaultTheme: getThemeMode(),
    themes: {
      light: {
        dark: false,
        colors: {
          ...theme,
          "input-text-color": "#FFFFFF",
          "button-text-color": "#000000",
        },
      },
      dark: {
        dark: true,
        colors: {
          ...theme,
          "input-text-color": "#000000",
          "button-text-color": "#FFFFFF",
        },
      },
    },
  },
  defaults: {
    TheButton: components.VBtn,
    TheProgressLinear: components.VProgressLinear,
    TheProgressCircular: components.VProgressCircular,
    TheChip: components.VChip,
    TheTextField: components.VTextField,
  },
});
