import { DEFAULT_LANGUAGE } from "@/constants/localizationConstants";
import { createI18n } from "vue-i18n";
import { Trans } from "./translation";

const userPreferredLocale = Trans.getDefaultLocaleAndLoadMessages();

export const i18n = createI18n({
  legacy: false,
  locale: userPreferredLocale || DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  formatFallbackMessages: true,
  messages: {},
});
